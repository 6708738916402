<template>
  <div>
    <div class="breadcrumb-area">
      <div class="container">
        <div class="breadcrumb-content">
          <ul>
            <li>
              <router-link to="/home">Home</router-link>
            </li>
            <li style="padding: 0;">
              <i class="el-icon-arrow-right"></i>
            </li>
            <li>{{ pageName }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageName: {
      type: String,
      default: 'Shop',
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb-area {
  border-top: 1px solid #ebebeb;
  padding-top: 25px;
  li {
    display: inline-block;
    padding: 0 10px;
    position: relative;
    &:first-child {
      padding-left: 0;
    }
    a {
      color: #333;
      &:hover {
        color: #fed100;
      }
    }
  }
}
</style>